// const KEYCLOAK_REALM_NAME = 'search' // keycloak realm
// const KEYCLOAK_CLIENT_ID = 'search-ui' // 사용할 client
// const KEYCLOAK_URL = 'https://keycloak.search.t3q.ai/auth/' // keycloak url
const KEYCLOAK_REALM_NAME = 'kpf-dev' // keycloak realm
const KEYCLOAK_CLIENT_ID = 'search-ui' // 사용할 client
const KEYCLOAK_URL = 'https://auth.kpf-dev.t3q.ai/' // keycloak url


// 위에 설정한 realm, client_id, url 로 keycloak instance 를 생성한다.
// const keycloak_config = {
//   realm: process.env.KEYCLOAK_REALM_NAME || KEYCLOAK_REALM_NAME,
//   clientId: process.env.KEYCLOAK_CLIENT_ID || KEYCLOAK_CLIENT_ID,
//   url: process.env.KEYCLOAK_URL || KEYCLOAK_URL,
// }

const keycloak_config = {
  realm: process.env.KEYCLOAK_REALM_NAME || KEYCLOAK_REALM_NAME,
  clientId: process.env.KEYCLOAK_CLIENT_ID || KEYCLOAK_CLIENT_ID,
  url: process.env.KEYCLOAK_URL || KEYCLOAK_URL
}

export default keycloak_config
